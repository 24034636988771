/*
    SLIDER
*/

:root {
    --swiper-theme-color: #FFFFFF;
}

.main-slider-wrapper {        // .swiper-container
    
    // Slider size
    width: 100%;
    height: 50vw;
    //
    margin: 0 auto;

    @include breakpoint(medium) {
        //height: 30px;
    }

    @include breakpoint(large) {
        //height: 380px;
    }
    
    .swiper-container {
        width: 100%;
        height: 100%;
    
        .swiper-wrapper {
            width: 100%;
            height: 100%;

            .swiper-slide {
                height: 100%;
                position: relative;
                box-sizing: border-box;

                @include breakpoint(medium) {
                }

                @include breakpoint(large) {
                }

                .slide-inner {
                    width: 100%;
                    height: 100%;

                    // Position, margins & paddings
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;

                    // Ubicación del contenido
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    @include breakpoint(medium) {

                    }

                    @include breakpoint(large) {

                    }

                }

                .slide-bgr {
                    position: absolute;
                    z-index: 0;
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: none;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    
}

// Nav Pagination

.swiper-pagination-bullet {
    background-color: $light-gray;
    opacity: .5;
    width: 1rem;
    height: 1rem;
    margin: 0 .375rem;

    &.swiper-pagination-bullet-active {
        opacity: 1;
    }
}

/*
// Lineas
.swiper-pagination-bullets {
    bottom: 1.25rem !important;

    .swiper-pagination-bullet {
        background-color: white;
        opacity: 1;
        width: 2rem;
        height: .375rem;
        margin: 0 .5rem;
        border-radius: 0;
        transition: all .5s cubic-bezier(.67,.06,.4,1);

        &.swiper-pagination-bullet-active {
            opacity: 1;
            width: 4rem;
            background-color: $primary-color;
        }
    }
}
*/