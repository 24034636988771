/*
    GLOBAL
*/

.fw-light { font-weight: 300; }
.fw-regular { font-weight: 400; }
.fw-medium { font-weight: 500; }
.fw-semibold { font-weight: 600; }
.fw-bold { font-weight: 700; }

section {
    padding: 2.5rem 0;

    @include breakpoint(medium) {
        //padding: 2rem 0;
    }
}

h1 {
    font-weight: 300;
}

h2, h3, h4, h5 {
    font-weight: 700;
    margin-bottom: 1em;
}

.section-title {
    padding: 2.5rem 0;
    
    h1 {
        position: relative;
        padding-bottom: 1em;
        
        &:after {
            content: '';
            width: 6rem;
            height: 2px;
            background: black;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -3rem;
        }
    }
    
    @include breakpoint(large){
        padding: 3.5rem 0;
    }
}

.section-intro {
    padding: 0 0 2rem;
    max-width: 56rem;
    margin: 0 auto;
    
    @include breakpoint(large){
        padding: 0 0 3rem;
    }
}

.bold {
    font-weight: bold !important;
}

a.button,
button.button {
    font-weight: 600;
    
    &.left-icon {
        text-align: left;
        display: inline-flex;
        align-items: center;
        line-height: 1.25;

        i {
            font-size: 1.5em;
            margin-right: .5rem;
        }
    }
    
   
}

.mb0 { margin-bottom: 0 !important; }
.mb1 { margin-bottom: 1rem !important; }
.mb2 { margin-bottom: 2rem !important; }
.mb3 { margin-bottom: 3rem !important; }
.mb4 { margin-bottom: 4rem !important; }


.primary-bgr {
    background-color: $primary-color;
    color: white;
}
.secondary-bgr {
    background-color: $secondary-color;
    color: white;
}

.space-between { justify-content: space-between; }
.space-around { justify-content: space-around; }


// Accordion and dropdown menus arrow
.dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a::after,
.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
    display: block;
    width: auto;
    height: auto;
    border: 0 !important;
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    right: 0;

    content: '\f078';
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-size: 1em;
    color: $medium-gray;
}

// Dropdown menu arrow
.dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a::after {
    color: $primary-color;
}

//
ul.check-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        padding: 0 0 0 1.5em;
        position: relative;

        &:before {
            content: '\f00c';
            position: absolute;
            left: 0;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            font-size: 1em;
            color: $primary-color;
        }
    }
}

/**** */

@mixin img-cover {
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
}

@mixin img-contain {
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: contain;
}

@mixin reset-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin check-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        padding: 0 0 0 1.5em;
        margin-bottom: .5rem;
        position: relative;

        &:before {
            content: '\f00c';
            position: absolute;
            left: 0;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            font-size: 1em;
            color: $primary-color;
        }
    }
}

/**** */

.top-placeholder {
    height: 102px;
    transition: all .3s ease-out;
    border-bottom: .5rem solid $primary-color;
    
    @include breakpoint(large) {
        height: 152px;
    }
}
.topbanner {
    background: white;
    padding-top: 5rem;
    border-bottom: .5rem solid $primary-color;

    .bgr {
        height: 9rem;
        background: url('/img/header-bgr.jpg') center bottom / auto 100% repeat-x;
    }
    
    @include breakpoint(large) {
        padding-top: 7rem;

        .bgr {
            height: 13rem;
        }
    }
}

@include breakpoint(large) {
    
    .txt-lg {
        font-size: 1.125rem;
    }
        
}



@mixin items-grid(
    $cols: 2, 
    $item-margin: 1rem) {

    $col-width: 100% / $cols;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -$item-margin;
    margin-right: -$item-margin;
    margin-bottom: $item-margin * 3;

    .item {
        width: calc(#{$col-width} - #{$item-margin} * 2);
        margin: $item-margin;
    }
}

.items-grid {
    .item {
        font-size: .875rem;
        h5 {
            font-size: 1.16em;
            margin-bottom: .25em;
            color: $primary-color;
            font-weight: 700;
        }
    }
}

.gray-bgr {
    background: $light-gray;
    color: black;
    padding: 2.5rem 0;
    
    @include breakpoint(large) {
        padding: 3.5rem 0;
        
    }
    
    & + .gray-bgr {
        position: relative;
        
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $body-background;
        }
    }
}

.block {
    padding: 2.5rem 0;

    @include breakpoint(large) {
        padding: 3.5rem 0;

    }
}

.editor-txt {
    padding-right: 6%;
    
    h4, h5 {
        color: $primary-color;
    }
    p {
        font-size: .875rem;
    }
    ul {
        @include check-list;
        font-size: .875rem;
    }
    
    @include breakpoint(medium) {
        p {
            font-size: 1rem;
        }
        ul {
            font-size: 1rem;
        }
    }
}

.sidemenu-container {

    .sidemenu-trigger {
        padding: .75rem .75rem .75rem 0;
        position: relative;
        color: black;
        

        &:after {
            content: '\f078';
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            font-size: 1rem;
            position: absolute;
            right: 0;

        }
    }

    .sidemenu-wrapper {
        display: none;  
        margin-bottom: 1rem;
    }

    @include breakpoint(medium) {
        .sidemenu-trigger {
            display: none;
        }
        .sidemenu-wrapper {
            display: block;    
        }
    }
}

.sidemenu {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid $medium-gray;
    
    li {
        border-bottom: 1px solid $medium-gray;
        
        a {
            position: relative;
            display: block;
            padding: .75rem .75rem .75rem 0;
            color: $dark-gray;
            
            &:hover {
                color: $primary-color;
            }
            
            &.active {
                color: $primary-color;
                font-weight: 700;
            }
            
            &:after {
                content: '\f054';
                font-family: "Font Awesome 5 Pro";
                font-weight: 400;
                font-size: 1rem;
                position: absolute;
                right: .25rem;

            }
        }
    }
    
    @include breakpoint(large) {
        li {
            a {
                padding: 1rem 1rem 1rem 0;
            }
        }
    }
}