/*
    LOGIN
*/

body#mi-consorcio-pg {
    background: white;
    
    main {
        padding-top: 1.5rem;
    }
    
    @include breakpoint(large) {
        main {
            padding-top: 2.5rem;
        }
    }
}

.consorcio-header {
    margin-bottom: 2rem;
}

.consorcio-desc {
    a.button {
        margin: 0 .5rem .5rem 0;
    }
    
    @include breakpoint(small only) {
        h1 {
            font-size: 1.5rem;
        }
    }
}

.reserva-amenities {
    margin-top: 2rem;
    padding: 1.25rem;
    //border: 1px solid $primary-color;
    border-radius: 3px;
    background: $light-gray;
}

.news-search {
    background: $light-gray;
    border-radius: 3px;
    padding: 1.25rem;
    margin: 1.25rem 0;
    
    p {
        color: black;
    }
    
    .button {
        margin: 0;
    }
}

.news-list {
    border-top: 1px solid $medium-gray;
    margin-bottom: 2rem;
    
    .news-item {
        padding: 1.5rem 0;
        border-bottom: 1px solid $medium-gray;
        
        .news-date {
            font-size: .75rem;
            margin-bottom: .5rem;
        }
        a.button {
            margin: 0 .25rem .25rem 0;
        }
    }
}