/*
    FOOTER
*/



footer {
    background: $primary-color;
    //margin-top: 5rem;
    color: white;
    padding: 1.5rem;
    text-align: center;
    
    p {
        font-size: .875rem;
    }
    a {
        color: white;
        text-decoration: underline;
        
        &:hover {
            color: white;
            
        }
    }
    
    .logos {
        a {
            display: inline-block;
            vertical-align: top;
            margin: .5rem 0;
        }
    }
    
    @include breakpoint(large) {
    
        text-align: left;
        
        .txt {
            height: 100%;
            display: flex;
            align-items: center;
        }
        
        p {
            margin: 0
        }
        
      
    }
}
