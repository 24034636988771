/*
    SERVICES
*/

body#marketplace-pg {
    background: white;
}

.marketplace-grid {
    @include items-grid(1,.75rem);
    margin-top: -.75rem;
    justify-content: flex-start;
    
    .label {
        margin-bottom: .375rem;
    }
    
    h5 {
        margin-bottom: .8em !important;
    }
    
    p, ul {
        margin-bottom: .5em !important;
    }
    
    .grid-img {
        margin: 0 auto .5rem;
        
        .img-wrapper {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            background: rgba(0,0,0,.1);
            
            img {
                @include img-contain;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    
    @include breakpoint(medium){
        @include items-grid(2,.75rem);
        justify-content: flex-start;
    }
    
    @include breakpoint(large){
        @include items-grid(3,1rem);
        margin-top: -1rem;
        justify-content: flex-start;
        
        .grid-img {
            margin: 0 auto .75rem;
            font-size: 2rem;
        }
    }
}