/*
    Hamburger settings
    https://github.com/jonsuh/hamburgers
*/

$hamburger-padding-x           : 10px;
$hamburger-padding-y           : 10px;
$hamburger-layer-width         : 24px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : $secondary-color;
$hamburger-layer-border-radius : 2px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

.hamburger {
    position: relative;
    z-index: 102;
    outline: none;
}