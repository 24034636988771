/* 
    PRELOADER 
*/

@keyframes elastica {
    0% {
        transform-origin: 0% 0%;
        transform: scale(0, 1);
    }
    50% {
        transform-origin: 0% 0%;
        transform: scale(1, 1);
    }
    50.1% {
        transform-origin: 100% 0%;
        transform: scale(1, 1);
    }
    100% {
        transform-origin: 100% 0%;
        transform: scale(0, 1);
    }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 5px;
    z-index: 2000;
    transition: all 300ms linear;
    pointer-events: none;

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $primary-color;
        animation: elastica 2.555s cubic-bezier(1, 0, 0, 1) infinite;
    }

    &.on {
        opacity: 1;
        visibility: visible;
    }

    .preinner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
    }
}