/*
    HOME
*/

body#home-pg {
    .grid-container:not(.fluid) {
        max-width: 55rem;
    }
    
    .intro {
        padding: 2rem 0 4rem;
    }
    
    .txt-lg {
        //margin-bottom: 3rem;
    }
    .video-wrapper {
        
    }
}

.phrase {
    color: white;
    background: $primary-color;
    padding: 1rem;
    
    h1 {
        font-size: 1rem;
        //line-height: 1.1;
        margin: 0 0 .5rem;
        color: white;
        font-weight: bold;
    }
    
    @include breakpoint(large) {
        font-size: 1.25rem;
    }
}

section#video {
    background-image: linear-gradient(to bottom, $white 60%, $light-gray 60%);
    padding-bottom: 4rem;
}

section#info {
    background: $light-gray;
}

.banners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    a {
        display: inline-block;
        margin: .75rem;
        flex: 0 0 calc(25% - 1.5rem);
        //min-width: calc(25% - 1.5rem);
        
        &[href=''] {
            pointer-events: none;
        }
    }
}