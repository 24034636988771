/*
    AGENDA AMENITIES
*/

$cal-dark-gray: #ccc;
$cal-default: #E5E5E5;
$cal-available: #00E5A9;
$cal-available-hover: #3AF2C1;
$cal-booked: #E5E5E5;
$cal-owned: #E5BA00;
$cal-blocked: #535353;

$cal-font-size: 15px;


.amenity-title {
    padding: .5rem;
    margin-bottom: 2rem;
    border: 1px solid $medium-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h2 {
        margin: 0;
        flex: 1;
    }
    
    .week-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 1rem;
        
        > a:hover {
            text-decoration: underline;
        }
        
        .datepicker-single {
            margin-left: .5rem;
            
            input {
                margin: 0;
                background: transparent;
                font-size: 1rem;
                padding: 1em .5em;
                width: 7rem;
                font-weight: 600;
                text-align: center;
            }
        }
    }
    
    .week-nav {
        display: flex;
        margin-left: 1rem;
        
        .arrow {
            padding: .25rem .5rem;
            font-size: 2rem;
        }
    }
    
    @include breakpoint(small only) {
        
        h2 {
            font-size: 1.375rem;
        }
        
        .week-selector {
            span {
                display: none;
            }
            .datepicker-single {

                input {
                    font-size: .75rem;
                    width: 5rem;
                }
            }
        }
    }
    
    .week-nav {
        margin-left: .5rem;

        .arrow {
            padding: .25rem .5rem;
            font-size: 1.25rem;
        }
    }
}

.calendar-wrapper {
    min-height: 300px;
    margin-bottom: 5rem;
    display: flex;
    
    .cal-columns-wrapper {
        display: flex;
        flex: 1;
                
        .cal-column {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: .5rem;
            
            &:not(:last-child) {
                border-right: 1px solid $cal-dark-gray;
            }
            
            .cal-column-header {
                span {
                    font-size: 1.25em;
                    line-height: 1.2;
                    font-weight: 700;
                    margin-bottom: .5rem;
                    display: block;
                    
                    span {
                        display: block;
                        font-size: .6em;
                        font-weight: 400;
                        margin: 0;
                    }
                }
            }
            
            .cal-dates {
                display: flex;
                flex-direction: column;
                flex: 1;
                
                .date {
                    width: 100%;
                    flex: 1;
                    font-size: $cal-font-size;
                    color: black;
                    
                    .date-inner {
                        display: block;
                        position: relative;
                        padding: .5rem .625rem .5rem;
                        border-radius: .25rem;
                        background: $cal-default;
                        height: 100%;
                        color: black;
                        transition: all .2s ease-out;
                    
                        .date-title {
                            font-size: .8em;
                            color: black;
                            //margin-bottom: .5rem;;
                        }

                        .date-user,
                        .date-reservar {
                            font-size: .85em;
                            font-weight: 700;
                            //margin-bottom: .5rem;
                        }
                        
                        .date-cancel {
                            position: absolute;
                            right: .5rem;
                            top: .375rem;
                            display: block;
                            color: black;
                        }
                        
                        &:hover {
                            transform: scale(1.1);
                            //filter: brightness(1.2);
                        }
                    }
                    
                    &.booked {
                        .date-inner {
                            background: $cal-booked;
                        }
                    }
                    
                    &.owned {
                        .date-inner {
                            background: $cal-owned;
                        }
                    }
                    
                    &.available {
                        .date-inner {
                            background: $cal-available;
                        }
                    }
                    
                    &.blocked,
                    &.day-unavailable {
                        .date-inner {
                            background: $cal-blocked;
                            color: $light-gray;
                        }
                    }
                    
                    
        
                    &:not(:last-child) {
                        margin-bottom: .25rem;
                    }
                    
                    &.invalid {
                        opacity: .5 !important;
                        pointer-events: none !important;
                    }
                }
            }
        }
    }
    
    @include breakpoint(medium down) {
        position: relative;
        overflow-x: scroll;
        
        .cal-columns-wrapper {
            min-width: 940px;
        }
        /*
        &:after {
            content: '';
            position: sticky;
            z-index: 1;
            right: 0;
            top: 0;
            width: 10%;
            height: 100%;
            background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,.4));
        }
        */
    }
}

.popup {
    .popup-header {
        border-bottom: 1px solid $medium-gray;
        margin-bottom: 1rem;
        h3 {
            margin-bottom: .4em;
        }
    }
    
    p, ul {
        color: black;
        font-size: .875rem;
    }
    
    .red {
        color: $primary-color;
    }
    
    #cancel-book-form {
        display: inline;
        
        button {
            //font-size: .8em;
            color: $primary-color;
            cursor: pointer;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    table {
        font-size: .875rem;
        tr {
            border-bottom: 1px solid $medium-gray;
            td:first-child {
                padding-left: 0;
            }
        }
    }
    
    .popup-footer {
        border-top: 1px solid $medium-gray;
        padding-top: 1rem;
        margin-top: 1rem;
    
        button.disabled {
            opacity: .5;
            pointer-events: none;
        }
        
        
    }
}