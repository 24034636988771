/*
    SERVICES
*/

.services-grid {
    @include items-grid(1,.75rem);
    
    .grid-img {
        margin: 0 auto .5rem;
        font-size: 1.5rem;
        color: black;
        
        i {
            width: 2em;
            height: 2em;
            border: 1px solid $dark-gray;
            border-radius: 50%;
            line-height: 2em;
            text-align: center;
        }
    }
    
    @include breakpoint(medium){
        @include items-grid(2,.75rem);
    }
    
    @include breakpoint(large){
        @include items-grid(3,1rem);
        
        .grid-img {
            margin: 0 auto .75rem;
            font-size: 2rem;
        }
    }
    
    @include breakpoint(xlarge){
        @include items-grid(5,1rem);
    }
}