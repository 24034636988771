/*
    LOGIN
*/

body#login-pg {
    background: white;
}

.login-wrapper,
.profile-wrapper {
    max-width: 30rem;
    margin: 0 auto 5rem;
    border: 1px solid #ddd;
    padding: 3rem;
}

.profile-wrapper {
    max-width: 45rem;
}