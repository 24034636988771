/*
    COOKIES
*/

.cookies-popup {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem 2rem;
    background: rgba(0,0,0,.9);
    color: white;
    font-size: .75rem;
    line-height: 1.3;
    //font-weight: 300;

    transform: translateY(150%);
    transition: all 1s ease-out;

    a.button {
        margin: 0;
    }

    &.on {
        transform: translateY(0);

    }
    strong {
        //font-weight: 400;
    }

    .accept-btn {
        text-align: right;

        a {
            //font-weight: 400;
            //font-size: .875rem;
            //color: #00ff00;
            //text-decoration: none;
            text-align: right;

            //&:hover {text-decoration: underline}
        }
    }

    @media screen and (min-width: 640px) {
       
    }

    @media screen and (min-width: 1024px) {
        font-size: .875rem;
    }
}