/*
    HEADER
*/

// Header brakpoints
$mobile-header-upto: 'medium';
$desktop-header-from: 'large';

// Header padding based on container padding set on settings.scss
$container-small-padding: map-safe-get($grid-container-padding, small);
$header-small-padding: calc(#{$container-small-padding} / 2);
$container-medium-padding: map-safe-get($grid-container-padding, medium);
$header-medium-padding: calc(#{$container-medium-padding} / 2);

/***********************/
/*     Prevent FOUC    */
/***********************/
.no-js {
    @include breakpoint($mobile-header-upto down) {
        .top-bar { display: none; }
    }
    @include breakpoint($desktop-header-from) {
        .title-bar { display: none; }
    }
}
.top-bar, .top-bar ul { background: transparent; }


/***********************/
/*    CORE STYLING     */
/***********************/

header {
    position: relative;
    width: 100%;
    z-index: 100;
    
    .header-container {
        left: 0;            
        width: 100%;
        display: flex;
        justify-content: center;
        transition: background .3s ease-out;
       
        .header-inner {
            width: 100%;
            transition: all .3s ease-out;
            
            .top-logo {
                position: relative;
                z-index: 102;
            }
            
            nav {

                // Mobile menu
                @include breakpoint($mobile-header-upto down) {
                    display: flex;
                    flex-direction: column;
                
                    position: absolute;
                    width: 100vw;
                    //height: 100vh;
                    height: calc(var(--vh, 1vh) * 100);
                    
                    left: 0;
                    top: 0;
                    z-index: 101;
                    overflow: auto;
                    transition: all .3s ease-out;

                    // Init state
                    opacity: 0;
                    visibility: hidden;
                    
                    &.on {
                        opacity: 1;
                        visibility: visible;
                    }
                    
                }
            }
            
            .overlay-menu-logo {
                a {
                    display: block;
                }
                
                @include breakpoint($desktop-header-from) {
                    display: none !important;
                }
            }
        }
    } 
    
    // Header grid-contained
    &[data-width='container'] {
        .header-container {
            .header-inner {
                width: $global-width;
            }
        }
    }
    
    // Header fixed
    &[data-pos='relative'] {
        position: relative;
        background: white;
    }
    
    // Header fixed
    &[data-pos='absolute'] {
        position: absolute;
    }
    
    // Header fixed
    &[data-pos='fixed'] {
        position: fixed;
        top: 0;
        left: 0;
    }
    
    // Header fixed width delay
    &.is-fixed {
        position: fixed;
        //transition: none;
        
        .header-container.moveit {
            position: absolute;
            transform: translateY(-100%);
        }
    }
}

// Show mobile menu
body.show-menu {

    @include breakpoint($mobile-header-upto down) {    
        overflow: hidden;
    }
}

/***********************/
/*   CUSTOM STYLING    */
/***********************/
header {
    
    .header-container {
    
        .header-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            //background: white;
            
            // Top and bottom header margins
            padding: .5rem $header-small-padding;

            @include breakpoint(medium) {
                padding: 1rem $header-medium-padding;
            }

            nav {

                // Menu styling
                .main-menu {

                    // General styling
                    li {

                        a {
                            font-weight: 600;

                            &:not(.button) {
                                font-size: 1rem;
                                color: $dark-gray;
                                
                                &:hover,
                                &.active { 
                                    color: $primary-color;
                                }
                            }
                            
                            
                        }
                    }
                }
                
                .secondary-menu {
                    li {
                        a {
                            font-size: .875rem;
                            font-weight: 400;
                            color: $medium-gray;
                        }
                    }
                }
            }
        }
        
        // MOBILE OVERLAY WRAPPER
        @include breakpoint($mobile-header-upto down) {
            
            .header-inner {

                .top-logo {
                    width: 180px;
                }
                
                .top-opts {
                    display: none;
                }
                
                nav {
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    
                    padding: 1rem;
                    background: rgba(255,255,255,1);
                    
                    .overlay-menu-logo {
                        margin-bottom: 2rem;
                    }
                    
                    .main-menu {
                        min-width: 11rem;
                        
                        li {
                            a {
                                //font-size: 1.25rem;

                                &:hover {}
                                
                                &.button {
                                    margin-top: 1rem;
                                }
                            }
                            
                            ul {
                                margin-bottom: 1rem;
                                li {
                                    a {
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                    
                    .secondary-menu {
                        margin: 1.5rem 0 0;
                        
                        li {
                            a {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
        
        @include breakpoint($desktop-header-from) {
            
            .header-inner {
                align-items: flex-start;
                
                .top-logo {
                    width: 310px;
                    transition: all .3s ease-out;
                }
                
                nav {
                    align-items: flex-end;
                    padding-top: .5rem;
                    
                    .top-opts {
                        font-size: .875rem;
                        text-align: right;
                    }
                    
                    .main-menu {
                        li {
                            a {
                                &:hover {}
                                
                                &.button {
                                    margin-left: .75rem;
                                }
                            }
                            
                            &.logout {
                                
                                a {
                                    padding-left: 0;
                                    padding-right: 0;
                                    font-size: 1.25rem;
                                    line-height: .8;
                                }
                            }
                        }
                        
                        .submenu {
                            padding-bottom: 1rem;
                            border-bottom: 2px solid $primary-color;
                        }
                    }
                }
            }
        }
    }
    
    // Header becomes fixed
    &.is-fixed {

        .header-container {
            border-bottom: 1px solid #eee;
            background: white;

            .header-inner {
                padding-top: 0;
                padding-bottom: 0;
                
                @include breakpoint($desktop-header-from) {
                    align-items: center;
                    
                    .top-logo {
                        width: 250px;
                    }
                    
                    nav {
                        padding-top: 0;
                        padding-top: 0;

                        .top-opts {
                            display: none;
                        }
                    }
                }
                
            }
        }
    }
}


// Submenu
/*
.is-dropdown-submenu {
display: block !important;
opacity: 0;
visibility: hidden;
transition: all .3s ease-out; 

&.js-dropdown-active {
opacity: 1;
visibility: visible;
}

}
*/
