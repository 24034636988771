/*
    FONTS
*/

//
// GOOGLE WEBFONTS
//
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,700&display=swap');

//$crimson: "Crimson Text", Times, "Times New Roman", serif;
//
// LOCAL WEBFONTS
//
//@import url('/fonts/typicons/typicons.min.css');

// Font Awesome 5 Pro (v5.11.2);
@import 'fontawesome/all';

/*
@font-face {
    font-family: 'NeueHaasUnicaPro-Light';
    src: url('../fonts/2E39A8_2_0.eot');
    src: url('../fonts/2E39A8_2_0.eot?#iefix') format('embedded-opentype'),
        url('..//fonts/2E39A8_2_0.woff2') format('woff2'),
        url('..//fonts/2E39A8_2_0.woff') format('woff'),
        url('..//fonts/2E39A8_2_0.ttf') format('truetype');
}
*/

$opensans: 'Open Sans', Arial, sans-serif;
