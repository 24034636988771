/*
    STAFF
*/

.staff-grid {
    @include items-grid(2,.75rem);
    
    .staff-img {
        max-width: 70%;
        display: inline-block;
        margin: 0 auto 1rem;
        border-radius: 50%;
        overflow: hidden; 
    }
    
    @include breakpoint(medium){
        @include items-grid(3,.75rem);
    }
    
    @include breakpoint(large){
        @include items-grid(4,1rem);
    }
    
    @include breakpoint(xlarge){
        @include items-grid(5,1rem);
    }
} 